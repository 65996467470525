exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-wissen-index-js": () => import("./../../../src/pages/bio-wissen/index.js" /* webpackChunkName: "component---src-pages-bio-wissen-index-js" */),
  "component---src-pages-bio-wissen-sanity-post-category-sanity-post-slug-current-js": () => import("./../../../src/pages/bio-wissen/{sanityPost.category}/{sanityPost.slug__current}.js" /* webpackChunkName: "component---src-pages-bio-wissen-sanity-post-category-sanity-post-slug-current-js" */),
  "component---src-pages-dreh-fotogenehmigung-js": () => import("./../../../src/pages/dreh-fotogenehmigung.js" /* webpackChunkName: "component---src-pages-dreh-fotogenehmigung-js" */),
  "component---src-pages-expansions-angebot-js": () => import("./../../../src/pages/expansions-angebot.js" /* webpackChunkName: "component---src-pages-expansions-angebot-js" */),
  "component---src-pages-karriere-initiativbewerbung-index-js": () => import("./../../../src/pages/karriere/initiativbewerbung/index.js" /* webpackChunkName: "component---src-pages-karriere-initiativbewerbung-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-marktindex-js": () => import("./../../../src/pages/marktindex.js" /* webpackChunkName: "component---src-pages-marktindex-js" */),
  "component---src-pages-mittagsgerichte-newsletter-js": () => import("./../../../src/pages/mittagsgerichte-newsletter.js" /* webpackChunkName: "component---src-pages-mittagsgerichte-newsletter-js" */),
  "component---src-pages-neu-im-sortiment-js": () => import("./../../../src/pages/neu-im-sortiment.js" /* webpackChunkName: "component---src-pages-neu-im-sortiment-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-presseverteiler-js": () => import("./../../../src/pages/presseverteiler.js" /* webpackChunkName: "component---src-pages-presseverteiler-js" */),
  "component---src-pages-rezepte-index-js": () => import("./../../../src/pages/rezepte/index.js" /* webpackChunkName: "component---src-pages-rezepte-index-js" */),
  "component---src-pages-rezepte-sanity-recipe-slug-current-js": () => import("./../../../src/pages/rezepte/{sanityRecipe.slug__current}.js" /* webpackChunkName: "component---src-pages-rezepte-sanity-recipe-slug-current-js" */),
  "component---src-pages-ueber-uns-index-js": () => import("./../../../src/pages/ueber-uns/index.js" /* webpackChunkName: "component---src-pages-ueber-uns-index-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-lunch-menue-page-js": () => import("./../../../src/templates/LunchMenuePage.js" /* webpackChunkName: "component---src-templates-lunch-menue-page-js" */),
  "component---src-templates-market-page-js": () => import("./../../../src/templates/MarketPage.js" /* webpackChunkName: "component---src-templates-market-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/NewsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/NewsSingle.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-offer-page-js": () => import("./../../../src/templates/OfferPage.js" /* webpackChunkName: "component---src-templates-offer-page-js" */),
  "component---src-templates-offer-preview-page-js": () => import("./../../../src/templates/OfferPreviewPage.js" /* webpackChunkName: "component---src-templates-offer-preview-page-js" */)
}

